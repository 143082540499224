body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  width: 90%;
  margin: 5px auto 30px auto;
}

.navbar a {
    color: white;
    text-decoration: none;
}
.chart {
  width: 50%;
  min-width: 350px;
  margin: 10px auto;
}

.react-datepicker__input-container input {
  font-weight: bold;
  text-align: center;
}
.stats-container {
    height: 100px;
    width: 50%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
}

.stats-item {
    padding: 10px;
    font-size: xx-large;
}
